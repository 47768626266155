.search-input-wrapper {
  width: 400px;
  min-width: 300px;
}

.search-input {
  background: #f5f5f5;
  height: 30px;
  font-size: 12px;
  width: 240px;
  padding: 0 8px;
  border: none;
  border-bottom: 1px solid $borderColor;
  border-radius: 2px;

  outline: none;
  box-sizing: border-box;

  &:focus {
    border-bottom: 1px solid mat-color($caste-app-primary);
  }
}

.mat-select.small-text .mat-select-value-text {
  font-size: 12px;
  color: #666;
}
