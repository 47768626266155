.button-large {
  width: 250px;
}

.button-100 {
  width: 100%;
}

.loadable-btn {
  justify-content: center!important;
}